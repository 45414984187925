

$color_1: black;
$color_2: #000;
$color_3: #fff;
$color_4: #26348b;
$color_5: white;
$font-family_1: none;
$background-color_1: transparent;
$background-color_2: #26348b;
$background-color_3: #fff;
$border-color_1: #26348b;

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-moz-keyframes fadein {
/* Firefox */
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-webkit-keyframes fadein {
/* Safari and Chrome */
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@-o-keyframes fadein {
/* Opera */
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

section#primeiro-conteudo {
	h3 {
		background: linear-gradient(to right, #26348b , #0b983a );
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		text-transform: uppercase;
		font-weight: 700;
		margin-bottom: 0;
	}
	a.borda {
		color: $color_2;
		width: 100px;
		height: 100px;
		position: relative;
		border-top: 2px solid #0b983a;
		border-left: 2px solid #0b983a;
		border-bottom: 2px solid #26348b;
		border-right: 2px solid #26348b;
		border-radius: 10px;
		border-top-right-radius: 2px;
		border-bottom-left-radius: 2px;
		text-decoration: none;
		padding: 6px 12px;
		transition: 300ms;
		&:hover {
			width: 100px;
			height: 100px;
			border-top: 2px solid #26348b;
			border-left: 2px solid #0b983a;
			border-bottom: 2px solid #0b983a;
			border-right: 2px solid #26348b;
			border-radius: 16px;
			border-top-right-radius: 14px;
			border-bottom-left-radius: 14px;
			padding: 6px 12px;
			font-weight: 500;
			transition: 300ms;
		}
		&:active {
			width: 100px;
			height: 100px;
			border-top: 2px solid #26348b;
			border-left: 2px solid #0b983a;
			border-bottom: 2px solid #0b983a;
			border-right: 2px solid #26348b;
			border-radius: 16px;
			border-top-right-radius: 14px;
			border-bottom-left-radius: 14px;
			padding: 6px 12px;
			font-weight: 500;
			transition: 300ms;
		}
	}
	p {
		margin-bottom: 0;
	}
}

.arrow-button {
	display: flex;
	width: 150px;
	color: $color_3;
	background-color: $background-color_1;
	padding: 4px 14px;
	border-radius: 20px;
	transition: all .3s ease;
	font-weight: bold;
	cursor: pointer;
	align-items: center;
	font-size: 16px;
	border: 1px solid #fff;
	text-decoration: none;
	>.arrow {
		width: 6px;
		height: 6px;
		border-right: 2px solid #fff;
		border-bottom: 2px solid #fff;
		position: relative;
		transform: rotate(-45deg);
		margin: 0 10px;
		transition: all .3s ease;
		&::before {
			display: block;
			background-color: $background-color_2;
			width: 6px;
			transform-origin: bottom right;
			height: 2px;
			position: absolute;
			opacity: 0;
			bottom: calc(-2px / 2);
			transform: rotate(45deg);
			transition: all .3s ease;
			content: "";
			right: 0;
		}
	}
	&:hover {
		>.arrow {
			transform: rotate(-45deg) translate(4px, 4px);
			border-color: $border-color_1;
			&::before {
				opacity: 1;
				width: 14px;
			}
		}
		background-color: $background-color_3;
		color: $color_4;
		text-decoration: none;
		border: 1px solid #fff;
	}
}
section#segundo-conteudo {
	h4 {
		font-size: 2rem;
		font-weight: 700;
		text-align: left;
		text-transform: uppercase;
		margin-right: 50px;
		background: linear-gradient(to right, #26348b 20%, #0b983a 0%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	
	a{
		text-decoration: none;
		color: #000;
		.card-p {
			padding: 40px 10px;
			width: 230px;
			height: 275px;
			box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
			border-radius: 4px;
			transform: scale(1);
			transition: 400ms;
			&:hover {
				transform: scale(1.02);
				h5 {
					color: $color_3;
				}
			}
			h5 {
				margin-top: 50px;
			}
			img {
				margin: 0 auto;
				width: 90px;
			}
		}
	}

	.bg01{
		position: relative;
		background-color: $background-color_3;
		background-image: none;
		background-size:cover;
		background-repeat: no-repeat;
		background-position-x: -100%;
		transition: 300ms;
		&:hover{
			background-image: url(../dist/images/home/HEALTHCARE_BG.webp);
			background-position: 100%;
			transition: 300ms;
			img {
				filter: brightness(100);
			}
		}
	}
	.bg02{
		background-color: $background-color_3;
		background-image: none;
		background-size:cover;
		background-repeat: no-repeat;
		background-position-x: -100%;
		transition: 300ms;
		&:hover{ 
			background-image: url(../../dist/images/home/VAREJO_BG.webp);
			
			background-position: 100%;
			transition: 300ms;
			img {
				filter: brightness(100);
			}
		}
	}
	.bg03{
		background-color: $background-color_3;
		background-image: none;
		background-size:cover;
		background-repeat: no-repeat;
		background-position-x: -100%;
		transition: 300ms;
		&:hover{ 
		background-image: url(../dist/images/home/PROF\ SERV_BG.webp);
		background-position: 100%;
		transition: 300ms;
		img {
			filter: brightness(100);
		}
		}
	}
	.bg04{
		background-color: $background-color_3;
		background-image: none;
		background-size:cover;
		background-repeat: no-repeat;
		background-position-x: -100%;
		transition: 300ms;
		&:hover{ 
		background-image: url(../dist/images/home/LOGISTICA_BG.webp);
		background-position: 100%;
		transition: 300ms;
		img {
			filter: brightness(100);
		}
		}
	}
	.bg05{
		background-color: $background-color_3;
		background-image: none;
		background-size:cover;
		background-repeat: no-repeat;
		background-position-x: -100%;
		transition: 300ms;
		&:hover{ 
		background-image: url(../dist/images/home/SERV\ FINAN_BG.webp);
		background-position: 100%;
		transition: 300ms;
		img {
			filter: brightness(100);
		}
		}
	}
	.bg06{
		background-color: $background-color_3;
		background-image: none;
		background-size:cover;
		background-repeat: no-repeat;
		background-position-x: -100%;
		transition: 300ms;
		&:hover{ 
		background-image: url(../dist/images/home/FACILITIES_BG.webp);
		background-position: 100%;
		transition: 300ms;
		img {
			filter: brightness(100);
		}
		}
	}
}

section#terceiro-conteudo {
	background-color: $background-color_3;
	h4 {
		font-size: 2rem;
		font-weight: 700;
		text-align: left;
		text-transform: uppercase;
		margin-top: 20px;
		background: linear-gradient(to right, #26348b 19%, #0b983a 0%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
	.my-slider-two {
		padding: 10px 20px;
		align-items: center;
	}
	.slick-initialized {
		.slick-slide {
			background-color: $background-color_1;
			height: auto;
			margin: 0;
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
	}
	.slick-slider {
		.slick-track {
			display: flex;
			align-items: center;
		}
		.slick-list {
			display: flex;
			align-items: center;
		}
	}
	.card-p {
		
		img {
			margin: 0 auto;
			width: 100px;
			height: auto;
			transform: scale(1);
			opacity: 85%;
			transition: 300ms;
			&:hover {
				transform: scale(1.1);
			}
		}
	}
}


@media only screen and (max-width:769px) {
	
	section#segundo-conteudo {
		a {
			.card-p {
				
				width: 100%;
			}
		}
		
		
		h4 {
			font-size: 1.5rem;
			text-align: end;
			color: $color_3;
			text-transform: uppercase;
			margin-right: 5px;
		}
		
	}
}
@media only screen and (max-width:526px) {
	section#primeiro-conteudo {
		margin-top: 20px;
		h3{
			font-size: 1.2rem;
		}
	}

	section#terceiro-conteudo {
		h4{
			font-size: 1.2rem;
		}
		.my-slider-two {
			padding: 0;
			img {
				width: 100% !important;
			}
		}
	}
}

